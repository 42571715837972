import { useFetchParentsQuery } from "../../store"
import Skeleton from '../Skeleton';
import { FaExternalLinkAlt } from "react-icons/fa"
import EditForm from "./EditForm";

export default function ListingsList({ accountId }) {
    const { data, isFetching } = useFetchParentsQuery({ accountId })
    let parentDiv = <div>No Info</div>
    let totalChildAsins = 0

    if (isFetching) {
        parentDiv = <Skeleton times={10} className="h-10 w-full" />;
    } else if (data) {
        parentDiv = data.map((parent, index) => {
            totalChildAsins = totalChildAsins + parent.childAsins.length
            return <div key={index} className="flex flex-row m-1 my-4 p-2 bg-sky-50 border rounded-xl shadow items-center">
                <div>
                    <a href={"https://www.amazon.com/dp/" + parent.asin} rel='noreferrer' target="_blank">
                        <div className="text-xs p-2 w-[8vw] max-h-[16vh] rounded-xl overflow-hidden"><img src={parent.main_image} alt="main_image" className="rounded-xl shadow-lg" /></div>
                    </a>
                </div>
                <div className="py-2 mx-4">
                    <EditForm parent={parent} accountId={accountId}></EditForm>
                    <div className="flex flex-row items-center">
                        <div className="font-bold mr-2">{parent.skus[0].seller_sku}</div>
                        <div className="mr-2">{parent.asin}</div>
                        <a href={"https://www.amazon.com/dp/" + parent.asin} rel='noreferrer' target="_blank">
                            <FaExternalLinkAlt></FaExternalLinkAlt>
                        </a>
                    </div>
                    <div className="my-1">{parent.item_name}</div>
                    {parent.sales_rank
                        ? <div className="text-xs font-bold">Sales Rank: {parent.sales_rank} in {parent.sales_rank_title}</div>
                        : <div></div>
                    }
                    {parent.sales_rank_cat
                        ? <div className="text-xs font-bold">Sales Rank: {parent.sales_rank_cat} in {parent.sales_rank_cat_title}</div>
                        : <div></div>
                    }
                    <div className="text-xs">Brand: {parent.brand}</div>
                    <div className="text-xs">Child Count: {parent.childAsins.length}</div>
                </div>
            </div>
        })
    }
    return <div>
        <div>Total Child Asin Count: {totalChildAsins}</div>
        {parentDiv}
    </div>
}
//import Skeleton from '../Skeleton';
import SalesTrafficReportParent from "./SalesTrafficReportParent";
//import SalesTrafficCampaignReport from "./SalesTrafficCampaignReport"

function SalesTrafficTable({ date, dateStr, accountId, parent }) {
    const filteredPerformance = SalesTrafficReportParent({ data: parent, date, accountId })
    //const campaignPerformance = SalesTrafficCampaignReport({ date, accountId })
    let tableContent
    tableContent =
        <div>
            <div className="text-xs text-center text-yellow-500 drop-shadow-sm">{parent.customParentName || parent.skus[0].seller_sku}</div>
            <div className="flex justify-center items-end rounded-lg py-1 px-2 mb-3">

                <div className="text-gray-400 pr-2">{date.substring(0, 4)}</div>
                <div className="font-bold text-gray-700">{dateStr}</div>
            </div>
            <div className="flex flex-col items-center rounded-lg shadow-md border p-2">
                <div>Total Sales</div>
                <div className="text-base font-bold">${Number(filteredPerformance.orderedProductSales.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            </div>
            {/*<div>
                
                <div className="grid grid-flow-col justify-around my-2 shadow-md border rounded-lg p-2 text-center">
                    <div className="my-2">
                        <div className="text-xs">TACOS</div>
                        {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>%{(campaignPerformance.spend / filteredPerformance.orderedProductSales * 100).toFixed(2)}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                    </div>
                    <div className="my-2">
                        <div className="text-xs">ACOS</div>
                        {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>%{(campaignPerformance.spend / campaignPerformance.sales * 100).toFixed(2)}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                    </div>
                </div>
                <div className="grid grid-flow-col justify-around my-2 shadow-md border rounded-lg p-2 text-center">
                    <div className="my-2">
                        <div className="text-xs">Ad Sales</div>
                        {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>${Number(campaignPerformance.sales.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : <div className='flex flex-row'><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                    </div>
                    <div className="my-2">
                        <div className="text-xs">Ad Spend</div>
                        {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>${Number(campaignPerformance.spend.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                    </div>
                </div>
                </div>*/}

            <div className="grid grid-flow-col justify-stretch my-2 shadow rounded-lg p-2">
                <div>
                    <div className="my-2">
                        <div className="text-xxs">Orders</div>
                        <div>{filteredPerformance.totalOrderItems.toFixed(0)}</div>
                    </div>
                    <div className="my-2">
                        <div className="text-xxs">Units</div>
                        <div>{filteredPerformance.unitsOrdered.toFixed(0)}</div>
                    </div>
                </div>
                <div>
                    <div className="my-2">
                        <div className="text-xxs">Sessions</div>
                        <div>{filteredPerformance.sessions.toFixed(0)}</div>
                    </div>
                    <div className="flex my-2">
                        <div>
                            <div className="text-4xs mr-1">Mobile</div>
                            <div className="text-xxs mr-1">{filteredPerformance.mobileAppSessions.toFixed(0)}</div>
                        </div>

                        <div>
                            <div className="text-4xs">Browser</div>
                            <div className="text-xxs">{filteredPerformance.browserSessions.toFixed(0)}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="my-2">
                        <div className="text-xxs">CVR</div>
                        <div>%{((filteredPerformance.unitsOrdered / filteredPerformance.sessions) * 100).toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div className="grid grid-flow-col justify-stretch my-2 shadow rounded-lg p-2">
                <div>
                    <div className="my-1">
                        <div className="text-xxs">Page Views</div>
                        <div>{filteredPerformance.pageViews.toFixed(0)}</div>
                    </div>
                    <div className="flex my-1">
                        <div>
                            <div className="text-4xs mr-1">Mobile</div>
                            <div className="text-xxs mr-1">{filteredPerformance.mobileAppPageViews.toFixed(0)}</div>
                        </div>

                        <div>
                            <div className="text-4xs">Browser</div>
                            <div className="text-xxs">{filteredPerformance.browserPageViews.toFixed(0)}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="my-1">
                        <div className="text-xxs">Avg.Price</div>
                        <div>${(filteredPerformance.orderedProductSales / filteredPerformance.unitsOrdered).toFixed(2)}</div>
                    </div>
                    <div className="my-1">
                        <div className="text-4xs">Avg.Sale/Order</div>
                        <div className="text-xxs">${(filteredPerformance.orderedProductSales / filteredPerformance.totalOrderItems).toFixed(2)}</div>
                    </div>
                </div>
                <div>
                    <div className="my-1">
                        <div className="text-xxs">Units Refunded</div>
                        <div>{filteredPerformance.unitsRefunded.toFixed(0)}</div>
                    </div>
                    <div className="my-1">
                        <div className="text-xxs">Refund Rate</div>
                        <div>%{((filteredPerformance.unitsRefunded / filteredPerformance.unitsOrdered) * 100).toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div className="text-xxs text-center text-gray-400">{parent.asin} - {parent.skus[0].seller_sku}</div>
        </div>


    return <div className="flex flex-col w-80 bg-sky-50 p-3 border shadow rounded-xl">
        {tableContent}
    </div>
}
export default SalesTrafficTable
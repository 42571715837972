import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const listingsApi = createApi({
    reducerPath: 'listings',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchListings: builder.query({
                query: ({ accountId }) => {
                    return {
                        url: '/api/fetchListings',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
            fetchParents: builder.query({
                query: ({ accountId, isDisplayedOnly }) => {
                    return {
                        url: '/api/fetchParents',
                        method: 'GET',
                        params: {
                            accountId,
                            isDisplayedOnly
                        }
                    }
                }
            }),
            updateParentsSku: builder.mutation({

                query: ({ accountId, asin, sku }) => {
                    console.log("updateParentSku - store: ", accountId, "-", asin, "-", sku)
                    return {
                        url: '/api/updateParentsSku',
                        method: 'POST',
                        body: {
                            accountId,
                            asin,
                            sku
                        }
                    }
                }
            }),
            updateParentState: builder.mutation({
                query: ({ state, accountId, id }) => {  //>>>
                    // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ parent state: ', state)
                    console.log('targetingApi@ account id: ', accountId)
                    console.log('targetingApi@ asin id: ', id)
                    return {
                        url: '/api/targetingsp/updateparentstatus',
                        method: 'POST',
                        body: {
                            state,
                            accountId,
                            id
                        }
                    }
                }
            }),

        }
    }
});

export { listingsApi };
export const { useFetchListingsQuery, useFetchParentsQuery,
    useUpdateParentsSkuMutation, useUpdateParentStateMutation } = listingsApi;
import { useState, useEffect } from "react";
import { FaEdit, FaCheckCircle, FaTimesCircle } from "react-icons/fa"
import { useUpdateParentsSkuMutation } from "../../store";
import EnableToggle from "./EnableToggle"

function EditForm({ accountId, parent }) {
    const [editForm, setEditForm] = useState(false)
    const [class1, setClass1] = useState("font-bold mr-3 p-2 rounded-md bg-sky-700 text-white border w-52")
    const [formValue, setFormValue] = useState(parent.customParentName || parent.skus[0].seller_sku)
    const [formValueReset, setFormValueReset] = useState(parent.customParentName || parent.skus[0].seller_sku)
    const [updateSku, response] = useUpdateParentsSkuMutation()

    useEffect(() => {
        if (response.isSuccess) {
            setClass1("font-bold mr-3 p-2 rounded-md bg-green-700 text-white border w-52")
        }
        if (response.isError) {
            setClass1("font-bold mr-3 p-2 rounded-md bg-red-700 text-white border w-52")
        }
    }, [response])

    const onEditClick = () => {
        setFormValueReset(formValue)
        setEditForm(true)
    }
    const onConfirmClick = async () => {
        await updateSku({ accountId, asin: parent.asin, sku: formValue })
        setEditForm(false)

    }
    const onCancelClick = () => {
        setFormValue(formValueReset)
        setEditForm(false)
    }
    const onChange = (event) => {
        const value = event.target.value;
        setFormValue(value);
    }
    return (
        <div className="flex flex-row justify-items-center">
            <div>
                {
                    editForm
                        ? <div className="flex flex-row items-center my-2">
                            <input type='text' value={formValue} onChange={onChange} className="font-bold mr-3 p-2 rounded-md border w-52"></input>
                            <div className="text-lg px-1 hover:cursor-pointer" onClick={onConfirmClick}><FaCheckCircle /></div>
                            <div className="text-lg px-1 hover:cursor-pointer" onClick={onCancelClick}><FaTimesCircle /></div>
                        </div >
                        : <div className="flex flex-row items-center my-2">
                            <div className={class1}>{formValue}</div>
                            <div className="text-lg px-1 hover:cursor-pointer" onClick={onEditClick}><FaEdit /></div>
                        </div>
                }
            </div>
            <div className="flex mx-3 items-center">
                <EnableToggle isChecked={parent.isDisplayed} asin={parent.asin}></EnableToggle>
            </div>
        </div>
    )
}
export default EditForm
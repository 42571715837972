import { useSelector } from "react-redux"
import { useState } from 'react'
import SalesTrafficList from "./SalesTrafficList"
import SalesTrafficListParent from "./SalesTrafficListParent"
import SelectAccountDiv from "../SelectAccountDiv"
import ToggleSwitch from "./PerformanceToggle"

function SalesTrafficPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [isWeekly, setIsWeekly] = useState(true)

    return <div className="my-5">
        <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">Sales and Traffic Page</div>
        {accountId
            ? <div>
                <div className="flex flex-row px-6 mt-4">
                    <div className="px-3">Monthly</div>
                    <ToggleSwitch isWeekly={isWeekly} setIsWeekly={setIsWeekly}></ToggleSwitch>
                    <div className="px-3">Weekly {isWeekly}</div>
                </div>
                <div className="">
                    <div className="my-1 overflow-scroll"><SalesTrafficList accountId={accountId} isWeekly={isWeekly}></SalesTrafficList></div>
                    <div className="my-1 overflow-scroll"><SalesTrafficListParent accountId={accountId} isWeekly={isWeekly}></SalesTrafficListParent></div>
                </div>
            </div>
            : <SelectAccountDiv></SelectAccountDiv>
        }

    </div>
}

export default SalesTrafficPage
import { useFetchParentsQuery } from "../../store"
import SalesTrafficTableParent from "./SalesTrafficTableParent"
import Skeleton from '../Skeleton';

function SalesTrafficList({ accountId, isWeekly }) {
    const { data, isFetching } = useFetchParentsQuery({ accountId, isDisplayedOnly: true })
    let dateArray = [], dateStrArray = [], dateStartArray = [], dateEndArray = [];
    let finalDiv = <div className="flex flex-row p-3 pb-6 mx-3" >No Info</div>
    if (isFetching) {
        finalDiv = <div className="flex flex-col p-3 pb-6 mx-3" ><Skeleton times={3} className="h-10 w-full" /></div>
    } else if (data) {
        if (isWeekly) {
            const weeksToDisplay = 100
            const dateNow = new Date();
            const offset = new Date().getTimezoneOffset()
            const daysSinceLastWeek = new Date(dateNow.getTime() + (offset * 60 * 1000)).getDay();
            //console.log(daysSinceLastWeek)
            for (let i = 0; i < weeksToDisplay; i++) {
                let start = new Date();
                let end = new Date();

                //console.log("offset ", offset)
                start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * i));
                end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * i));
                start = new Date(start.getTime() + (offset * 60 * 1000))
                end = new Date(end.getTime() + (offset * 60 * 1000))
                dateStartArray[i] = start.toISOString().split('T')[0];
                dateEndArray[i] = end.toISOString().split('T')[0];
                dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                    + " - "
                    + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
            }
        } else {
            const monthsToDisplay = 24
            const dateNow = new Date();
            const monthNow = dateNow.getMonth()
            const dayNow = dateNow.getDate()
            const yearNow = dateNow.getFullYear()
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let lastDayofMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if ((yearNow % 4 === 0) || ((yearNow % 4 === 1) && (monthNow === 1))) {
                lastDayofMonth[1] = 29
            }
            let monthLength = []

            let lastYearIndex
            console.log("Day: ", dayNow, " ,Month: ", monthNow, " ", months[monthNow], " ,Year: ", yearNow)
            for (let i = 0; i < monthsToDisplay; i++) {
                let start = new Date();
                let end = new Date();
                const month = ((monthNow - i) % 12 + 12) % 12

                //SET FULL YEAR --- DESIGN
                if (lastYearIndex) {
                    start.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
                    end.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
                }
                if (i > monthNow && !lastYearIndex) {
                    lastYearIndex = i
                    start.setFullYear(yearNow - 1)
                    end.setFullYear(yearNow - 1)
                }

                start.setDate(1);
                end.setDate(1);
                start.setMonth(month)
                end.setMonth(month)
                if (i === 0) {
                    console.log("end date: ", dayNow)
                    if (dayNow > 2)
                        end.setDate(dayNow - 2);
                }
                else {
                    end.setDate(lastDayofMonth[month]);
                }
                start = new Date(start.getTime())
                end = new Date(end.getTime())
                monthLength[i] = end.getDate()
                dateStartArray[i] = start.toISOString().split('T')[0];
                dateEndArray[i] = end.toISOString().split('T')[0];
                dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " "
                    + start.getDate()
                    + " - "
                    + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
            }
        }

        let columnCount = dateArray.length

        let parentsDiv = []
        //map from parent array

        parentsDiv = data.map((parent, index) => {
            let tableDiv = []
            for (let i = 0; i < columnCount; i++) {
                tableDiv.push(<div className="mx-3" key={i}>
                    <SalesTrafficTableParent date={dateArray[i]} dateStr={dateStrArray[i]} accountId={accountId} parent={parent}></SalesTrafficTableParent>
                </div>)
            }
            return <div className="flex flex-row p-3 pb-6 mx-3" key={index}>{tableDiv}</div>
        })
        //map end

        /*
        parentsDiv = data.map((parent, index) => {
            return <div className="flex flex-row p-3 pb-6 m-3 bg-sky-50 rounded-xl" key={index}>{parent.asin} - {parent.skus[0].seller_sku}</div>
        })
        */

        finalDiv = <div>{parentsDiv}</div>
    }
    return finalDiv
}

export default SalesTrafficList
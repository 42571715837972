import { useState, useEffect, useRef } from "react"
import { GoChevronDown, GoChevronUp } from 'react-icons/go'
import Panel from "../Panel"
import classNames from "classnames"

function Dropdown({ history: options, className, hidden }) {
    const [showMenu, setShowMenu] = useState(false)
    const divEl = useRef()
    const finalClassName = classNames('relative', className)
    const optionsClassName = classNames('absolute top-full max-h-64 overflow-scroll mt-2 z-10')

    useEffect(() => {
        const handler = (event) => {
            //if div is not visible, return earlier
            if (!divEl.current) {
                return
            }

            //if our ref div doesnt contain click event target (so it is outside of our div)
            if (!divEl.current.contains(event.target)) {
                setShowMenu(false)
            }
        }

        document.addEventListener('click', handler, true)

        return () => {
            document.removeEventListener('click', handler)
        }

    }, [])

    const onSelectClick = () => {
        setShowMenu(!showMenu);
    }

    const renderedOptions = options.map((option, index) => {
        const date = new Date(option.updatedDate)
        const updatedDate = date.toISOString().split('T')[0]
        const updatedTime = date.toISOString().slice(11, 16)

        return <div key={index} className="my-1 rounded-md">
            <div>{updatedDate} - {updatedTime} </div>
            <div className="flex">
                <div className="font-bold">{option.updatedFrom}{" >>> "}{option.updatedTo}  </div>
                <div className="flex ml-2 text-gray-400 text-3xs">{option.updatedBy}</div>
            </div>
        </div>

    })

    const reversedOptions = renderedOptions.reverse()

    return <div ref={divEl} className={finalClassName} hidden={hidden}>
        <div onClick={onSelectClick} className="hover:cursor-pointer">{!showMenu ? <GoChevronDown className="text-xl" /> : <GoChevronUp className="text-xl" />}</div>
        {showMenu && <Panel className={optionsClassName} >{reversedOptions}</Panel>}
    </div>
}

export default Dropdown;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const retrieveApi = createApi({
    reducerPath: 'retrieve',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchUser: builder.query({
                query: () => {
                    return {
                        url: '/api/user',
                        method: 'GET',
                    }
                }
            }),
            fetchProfiles: builder.query({
                query: () => {
                    return {
                        url: '/api/profiles',
                        method: 'GET',
                    }
                }
            }),
            fetchCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaigns',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchSbCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaignssb',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchSdCampaigns: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/campaignssd',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchPortfolios: builder.query({
                query: (accountId) => {
                    return {
                        url: '/api/portfolios',
                        method: 'GET',
                        params: {
                            accountId
                        },
                    }
                }
            }),
            fetchQuote: builder.query({
                query: () => {
                    return {
                        url: '/api/getQuote',
                        method: 'GET',
                        params: {

                        },
                    }
                }
            })
        }
    }
});

export { retrieveApi };
export const { useFetchProfilesQuery, useFetchCampaignsQuery,
    useFetchSdCampaignsQuery, useFetchSbCampaignsQuery, useFetchUserQuery,
    useFetchPortfoliosQuery, useFetchQuoteQuery } = retrieveApi;